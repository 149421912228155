import React from 'react';

import TermsAndConditions from '@auth/login/site/pages/TermsAndConditions';

import { useStaticQuery, graphql } from 'gatsby';

const pageQuery = () => {
  const query = graphql`
    query termsAndConditionPageMetaData {
      site {
        siteMetadata {
          siteUrl
          serviceApiUrl
        }
      }
    }
  `;

  const { site } = useStaticQuery(query);

  if (site == null || site.siteMetadata == null) {
    throw new Error('Failed to fetch Graphql data for portal/pages/Index.tsx');
  }

  return site.siteMetadata;
};

const IndexPage = () => {
  const { serviceApiUrl, siteUrl } = pageQuery();
  return (
    <TermsAndConditions serviceApiUrl={serviceApiUrl} rootDomain={siteUrl} />
  );
};

IndexPage.noLayout = true;

export default IndexPage;
