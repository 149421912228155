import React from 'react';
import { Dropdown, Button, Input, Menu } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

interface IBooleanMenuProps {
  id: string;
  onChange: (id: string, value: boolean) => void;
}

const BooleanMenu = (props: IBooleanMenuProps) => {
  const handleClick = (e: any) => props.onChange(props.id, e.key === 'true');

  return (
    <Menu onClick={handleClick}>
      <Menu.Item key="true">YES</Menu.Item>
      <Menu.Item key="false">NO</Menu.Item>
    </Menu>
  );
};

const RoleMenu = (props: IBooleanMenuProps) => {
  const handleClick = (e: any) => props.onChange(props.id, e.key === 'admin');

  return (
    <Menu onClick={handleClick}>
      <Menu.Item key="admin">Admin</Menu.Item>
      <Menu.Item key="user">User</Menu.Item>
    </Menu>
  );
};

export enum InlineEditFieldTypes {
  Boolean,
  Role,
}

interface IInlineEditFieldProps<T> {
  id: string;
  value: T;
  label: string;
  isEditing: boolean;
  onChange: (id: string, value: T) => void;
  onEdit: (id: string) => void;
  type?: InlineEditFieldTypes;
}

export const InlineEditField = <T extends {}>({
  id,
  value,
  label,
  isEditing,
  type,
  onChange,
  onEdit,
}: IInlineEditFieldProps<T>) => {
  return (
    <div style={{ width: 80 }} onClick={() => onEdit(id)}>
      {isEditing === false ? (
        <span>{label || value}</span>
      ) : typeof value === 'boolean' ? (
        <Dropdown
          overlay={
            type === InlineEditFieldTypes.Role ? (
              <RoleMenu id={id} onChange={(i, v) => onChange(i, v as any)} />
            ) : (
              <BooleanMenu id={id} onChange={(i, v) => onChange(i, v as any)} />
            )
          }
        >
          <Button type="link">
            {label}
            <DownOutlined />
          </Button>
        </Dropdown>
      ) : (
        <Input placeholder={String(value)} />
      )}
    </div>
  );
};
