import React from 'react';

import Page401 from '@auth/login/site/pages/401';

const IndexPage = () => {
  return <Page401 />;
};

IndexPage.noLayout = true;

export default IndexPage;
