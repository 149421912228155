import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Settings } from '@antv/gatsby-theme-antv/site/components/Settings';

const pageQuery = () => {
  const query = graphql`
    query settingsSiteMeta {
      site {
        siteMetadata {
          siteUrl
          serviceApiUrl
        }
      }
    }
  `;

  const { site } = useStaticQuery(query);

  if (site == null || site.siteMetadata == null) {
    throw new Error('Failed to fetch Graphql data for portal/pages/Index.tsx');
  }

  return site.siteMetadata;
};

const IndexPage = () => {
  const { serviceApiUrl, siteUrl } = pageQuery();

  return <Settings rootDomain={siteUrl} serviceApiUrl={serviceApiUrl} />;
};

export default IndexPage;
