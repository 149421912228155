import React from 'react';
import SEO from '@antv/gatsby-theme-antv/site/components/Seo';
import Banner from '@antv/gatsby-theme-antv/site/components/Banner';
import BannerSVG from '@antv/gatsby-theme-antv/site/components/BannerSVG';

import iOSSVG from '@antv/gatsby-theme-antv/site/images/ios.svg';
import AndroidSVG from '@antv/gatsby-theme-antv/site/images/android.svg';
import HTMLSVG from '@antv/gatsby-theme-antv/site/images/html5.svg';

import { Card } from '@antv/gatsby-theme-antv/site/components/Card';
import { ContainerWrapper } from '@antv/gatsby-theme-antv/site/components/ContainerWrapper';

const IndexPage = () => {
  const bannerButtons = [
    {
      text: 'Mobile SDK',
      type: 'primary',
      link: '/docs/platforms/introduction',
    },
    {
      text: 'Mini Programs',
      link: '/docs/platforms/vas/overview',
    },
  ];

  const notifications: any = [];

  /*
  [
    {
      type: 'News',
      title: 'New Developer Portal is live',
      date: '30.06.2020',
      link: 'https://bluecode.com',
    },
    {
      type: 'News',
      title: 'New Mobile SDK v5.2.1 released',
      date: '14.06.2020',
      link: 'https://bluecode.com',
    },
  ];
  */

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 34px)',
        background: 'linear-gradient(225deg, #ffffff, #f0f5ff)',
      }}
    >
      <SEO title="Bluecode Developer Portal" lang="en" />
      <Banner
        coverImage={<BannerSVG />}
        title="Mobile payment for Europe."
        notifications={notifications}
        description="Bluecode is the mobile payment solution that combines cashless payments via smartphones with value-added services and enables payments with merchant and banking apps. To enable our potential partners to take part of the evolution we are offering dedicated frameworks for various platforms. Feel free to browse the latest developer documentation, API reference, articles or sample code."
        className="banner"
        buttons={bannerButtons}
        video="https://www.youtube.com/embed/EOkLndkQ4Rc"
        onCloseVideo={() => {
          console.log('close'); // eslint-disable-line no-console
        }}
        onPlayVideo={() => {
          console.log('play'); // eslint-disable-line no-console
        }}
      />
      <ContainerWrapper>
        <Card
          title="iOS SDK"
          body="Access resources about how to integrate the Bluecode SDK into third party apps natively or using cross platform tools."
          link="/docs/platforms/mobile/v7/native/getting-started-ios"
          Icon={iOSSVG}
        />
        <Card
          title="Android SDK"
          body="Access resources about how to integrate the Bluecode SDK into third party apps natively or using cross platform tools."
          link="/docs/platforms/mobile/v7/native/getting-started"
          Icon={AndroidSVG}
        />
        <Card
          title="Mini Programs"
          body="Access resources about how to integrate web based mini apps into the Bluecode platform by extending value added services across mobile apps"
          link="/docs/platforms/vas/overview"
          Icon={HTMLSVG}
        />
      </ContainerWrapper>
    </div>
  );
};

export default IndexPage;
