import * as React from 'react';
import ReactPlayer from 'react-player';

import styles from './ImgToVideoModal.module.less';

interface ImgToVideoModalProps {
  url: string;
}

export const ImgToVideoModal = ({ url }: ImgToVideoModalProps): JSX.Element => (
  <div className={styles.imageToVideoModal}>
    <ReactPlayer
      width="100%"
      height="326px"
      loop={true}
      controls={true}
      url={url}
      config={{
        youtube: {
          playerVars: {
            showIngo: 0,
          },
        },
      }}
    />
  </div>
);
