import * as React from 'react';
import { Card, Form, Input, Button, Modal } from 'antd';

import { AccountProvider } from 'bc-providers';
import { getTokenFromString } from 'bc-cookies';

import styles from './Settings.module.less';
import {
  showSuccessNotification,
  isResponseError,
  handleError,
  showErrorNotification,
} from '../utils';

interface ISettingProps {
  rootDomain: string;
  serviceApiUrl: string;
}

const deleteAccount = async (rootDomain: string, serviceApiUrl: string) => {
  (window as any).appBaseServiceUrl = serviceApiUrl;

  const token = getTokenFromString(document.cookie);
  const data = await AccountProvider.remove(token);

  if (isResponseError(data)) {
    return handleError(data);
  }

  showSuccessNotification(data.data.message);
  setTimeout(() => (window.location.href = rootDomain), 2000);
};

export const Settings = (props: ISettingProps) => {
  const changePassword = async ({
    currentPassword,
    newPassword,
    confirmPassword,
  }: any) => {
    if (newPassword !== confirmPassword) {
      return showErrorNotification(
        'New password differs from confirmation password',
      );
    }

    (window as any).appBaseServiceUrl = props.serviceApiUrl;

    const token = getTokenFromString(document.cookie);
    const data = await AccountProvider.changePassword(
      currentPassword,
      confirmPassword,
      token,
    );

    if (isResponseError(data)) {
      return handleError(data);
    }

    showSuccessNotification(data.data.message);
  };

  const openDeleteModal = async () => {
    Modal.destroyAll();
    Modal.confirm({
      title: 'Delete Account',
      content: (
        <div>
          <p>We are sorry to see you go.</p>
          <p>
            Please note that account deletion is final. There will be no way to
            restore your account.
          </p>
        </div>
      ),
      width: '380px',
      okText: 'Delete my Account',
      cancelText: 'Cancel',
      onOk: () => deleteAccount(props.rootDomain, props.serviceApiUrl),
      onCancel: () => Modal.destroyAll(),
    });
  };

  return (
    <div className={styles.settings}>
      <div className={styles.wrapper}>
        <Card
          title="Change your password"
          bordered={true}
          style={{ borderRadius: '20px' }}
        >
          <p>
            In order to change your associated account password please fill out
            the input fields and proceed by pressing the button.
          </p>
          <Form
            name="change-password-form"
            className="change-password-form"
            onFinish={(v) => changePassword(v)}
            style={{ marginTop: '20px' }}
          >
            <Form.Item
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: 'Please enter your current password.',
                },
              ]}
            >
              <Input
                className={styles.input}
                type="password"
                placeholder="Current password"
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              rules={[
                { required: true, message: 'Please enter your new password.' },
              ]}
            >
              <Input
                className={styles.input}
                type="password"
                placeholder="New password"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Please re-enter your new password.',
                },
              ]}
            >
              <Input
                className={styles.input}
                type="password"
                placeholder="Confirm password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                shape="round"
                type="primary"
                htmlType="submit"
                className="change-password-button"
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card
          title="Delete account"
          bordered={true}
          style={{ marginTop: '20px', borderRadius: '20px' }}
        >
          <p>
            You will be asked to confirm that you want to delete your account.
            There is no undo, so please be careful! Know that you will not be
            able to recover your account, or any information on it once you go
            through with the deletion.
          </p>
          <Button
            shape="round"
            type="dashed"
            className="delete-account-button"
            style={{ marginTop: '10px' }}
            onClick={() => openDeleteModal()}
          >
            Delete my account
          </Button>
        </Card>
      </div>
    </div>
  );
};

export default Settings;
