import React from 'react';
import classNames from 'classnames';

import { Link } from 'gatsby';
import { CaretRightOutlined } from '@ant-design/icons';

import Notification, { NotificationProps } from './Notification';

import { showVideoModal } from './VideoModal';

import styles from './Banner.module.less';

import 'video-react/dist/video-react.css';

type BannerButtonShape = 'round' | 'square';

interface BannerButton {
  text: string;
  link: string;
  style?: React.CSSProperties;
  type?: string;
  shape?: BannerButtonShape;
}

interface BannerProps {
  coverImage?: React.ReactNode;
  title: string;
  description: string;
  notifications?: NotificationProps[];
  style?: React.CSSProperties;
  className?: string;
  video?: string;
  showGithubStars?: boolean;
  buttons?: BannerButton[];
  onCloseVideo?: () => void;
  onPlayVideo?: () => void;
}

const Banner: React.FC<BannerProps> = ({
  coverImage,
  title,
  description,
  notifications,
  style = {},
  className,
  video,
  buttons = [],
  onCloseVideo,
  onPlayVideo,
}) => {
  const insNotifications: NotificationProps[] = [];

  const notificationsNode = (notifications || insNotifications)
    .slice(0, 2)
    .map((notification, i) => (
      <Notification index={i} key={i} {...notification} />
    ));

  const renderButtons = buttons.map((button: BannerButton, i) => {
    const ButtonLink =
      button.link.startsWith('http') || button.link.startsWith('#')
        ? 'a'
        : Link;
    const buttonProps = {} as any;
    if (button.link.startsWith('http')) {
      buttonProps.target = '_blank';
      buttonProps.rel = 'noopener noreferrer';
    }
    if (ButtonLink === 'a') {
      buttonProps.href = button.link;
    } else {
      buttonProps.to = button.link;
    }
    const { shape = 'round' } = button;
    return (
      <ButtonLink
        {...buttonProps}
        className={classNames(
          styles.buttonLink,
          styles[button.type || ''],
          button.type === 'primary' ? 'primary-button' : 'common-button',
        )}
        key={i}
        style={{
          borderRadius: shape === 'round' ? '1000px' : '4px',
          ...button.style,
        }}
      >
        <span className={styles.button}>{button.text}</span>
      </ButtonLink>
    );
  });

  if (video) {
    renderButtons.push(
      <div
        key="video"
        onClick={() => showVideoModal(video, onPlayVideo, onCloseVideo)}
        className={styles.videoButtonWrapper}
      >
        <div className={styles.videoButton}>
          <CaretRightOutlined className={styles.videoButtonIcon} />
          <p
            className={styles.videoButtonText}
            style={{
              fontSize: '14px',
              lineHeight: '40px',
            }}
          >
            Play Video
          </p>
        </div>
      </div>,
    );
  }

  return (
    <section className={classNames(styles.wrapper, className)} style={style}>
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={classNames(styles.title, 'banner-title')}>
            {title}
          </div>
          <p className={classNames(styles.description, 'banner-description')}>
            {description}
          </p>
          <div className={classNames(styles.buttons, 'banner-buttons')}>
            {renderButtons}
          </div>
        </div>
        <div className={classNames(styles.notifications, 'notifications')}>
          {notificationsNode}
        </div>
      </div>
      <div className={classNames(styles.teaser, 'teaser')}>{coverImage}</div>
    </section>
  );
};

export default Banner;
