import * as React from 'react';
import ReactPlayer from 'react-player';

import { Modal } from 'antd';

import styles from './Banner.module.less';

interface VideoModalProps {
  url: string;
  onPlayVideo: () => void;
  onCloseVideo: () => void;
}

export const showVideoModal = (
  url: string,
  onPlayVideo?: () => void,
  onCloseVideo?: () => void,
) => {
  if (onPlayVideo) {
    onPlayVideo();
  }

  const videoHeight = `${((window.innerWidth / 100) * 70 * 9) / 16}px`;

  Modal.info({
    title: '',
    className: styles.videoModal,
    onCancel: onCloseVideo,
    content: (
      <ReactPlayer
        className={styles.video}
        width="100%"
        height={videoHeight}
        url={url}
        loop={true}
        controls={false}
        config={{
          youtube: {
            playerVars: {
              showIngo: 0,
              autoplay: 1,
            },
          },
        }}
      />
    ),
    width: '70%',
  });
};
