import * as React from 'react';

import styles from './ContainerWrapper.module.less';

interface IProps {
  children: any;
}

export const ContainerWrapper = ({ children }: IProps) => {
  return <div className={styles.wrapper}>{children}</div>;
};
