import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Administration from '@antv/gatsby-theme-antv/site/components/Administration';

const pageQuery = () => {
  const query = graphql`
    query administrationSiteMeta {
      site {
        siteMetadata {
          serviceApiUrl
        }
      }
    }
  `;

  const { site } = useStaticQuery(query);

  if (site == null || site.siteMetadata == null) {
    throw new Error('Failed to fetch Graphql data for portal/pages/Index.tsx');
  }

  return site.siteMetadata;
};

const IndexPage = () => {
  const { serviceApiUrl } = pageQuery();
  return <Administration serviceApiUrl={serviceApiUrl} />;
};

export default IndexPage;
