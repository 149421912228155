import * as React from 'react';

import { Button, Card, Input, Modal, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { getTokenFromString } from 'bc-cookies';
import { AdministratorProvider } from 'bc-providers';
import { IAccountDto } from 'bc-models/dist/dto';
import { InlineEditField, InlineEditFieldTypes } from './InlineEditField';

import {
  isResponseError,
  showErrorNotification,
  showSuccessNotification,
  handleError,
} from '../utils';

import styles from './Administration.module.less';

const createIdForInlineEditField = (id: string, key: string | number): string =>
  (id || '').concat(String(key));

const updateAccountProperty = async <T extends {}>(
  id: number,
  key: keyof IAccountDto,
  value: T,
): Promise<void> => {
  const token = getTokenFromString(document.cookie);
  const res = await AdministratorProvider.updateAccountProperty(
    token,
    id,
    key,
    value,
  );

  if (isResponseError(res)) {
    return handleError(res);
  }

  showSuccessNotification(res.data.message);
  return;
};

const fetchAccountTableData = (
  token: string,
  onComplete: (data: IAccountDto[]) => void,
) =>
  AdministratorProvider.getData(token).then((data) => {
    if (data == null) {
      return onComplete([]);
    }

    if (isResponseError(data as any)) {
      return onComplete([]);
    }

    const mappedData = ((data.data as IAccountDto[]) || []).map((d, index) => ({
      ...d,
      key: index,
    }));

    onComplete(mappedData);
  });

const filterAccountsForSearchQuery = (
  query: string,
  data: IAccountDto[] | null,
): IAccountDto[] | null =>
  data == null
    ? null
    : data.filter((d) =>
        Object.values(d).some((v) => String(v).indexOf(query) !== -1),
      );

const deleteAccount = (
  data: IAccountDto,
  onStart: () => void,
  onSuccess: () => void,
) => {
  const deleteRequest = async () => {
    onStart();

    const token = getTokenFromString(document.cookie);
    const res = await AdministratorProvider.deleteAccount(token, [data.id]);

    if (isResponseError(res)) {
      return showErrorNotification(res.data.message);
    }

    showSuccessNotification(res.data.message);
    onSuccess();
  };

  Modal.destroyAll();
  Modal.confirm({
    title: 'Delete Account',
    content: (
      <div>
        <p>Please confirm the account deletion for the following account:</p>
        <div>
          <strong>Company</strong>: {data.company}
        </div>
        <div>
          <strong>eMail</strong>: {data.email}
        </div>
      </div>
    ),
    width: '480px',
    okText: 'Delete',
    cancelText: 'Cancel',
    onCancel: () => Modal.destroyAll(),
    onOk: deleteRequest,
  });
};

interface IAccountTableProps {
  columnConfig: any[];
  data: IAccountDto[];
}

export const AccountTable = ({ columnConfig, data }: IAccountTableProps) => (
  <Table columns={columnConfig} dataSource={data} bordered pagination={false} />
);

interface IAdministrationProps {
  serviceApiUrl: string;
}

export const Administration = (props: IAdministrationProps) => {
  const [accounts, setAccounts] = React.useState<IAccountDto[] | null>(null);
  const [inlineEditingId, setInlineEditingId] = React.useState('');
  const [query, setSearchQuery] = React.useState<string>('');

  React.useEffect(() => {
    (window as any).appBaseServiceUrl = props.serviceApiUrl;
    const token = getTokenFromString(document.cookie);
    fetchAccountTableData(token, (data) => setAccounts(data));
  }, []);

  const onChange = async <T extends {}>(
    id: number,
    key: keyof IAccountDto,
    value: T,
  ) => {
    setAccounts(null);
    setInlineEditingId('');

    const token = getTokenFromString(document.cookie);

    await updateAccountProperty(id, key, value);
    await fetchAccountTableData(token, (accountData) =>
      setAccounts(accountData),
    );
  };

  const columnConfig = [
    {
      title: 'eMail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Role',
      dataIndex: '',
      key: 'isAdmin',
      render: (d: IAccountDto) => {
        const fieldId = createIdForInlineEditField('isAdmin', d.id);
        return (
          <InlineEditField
            id={fieldId}
            value={d.isAdmin}
            label={d.isAdmin ? 'Admin' : 'User'}
            isEditing={fieldId === inlineEditingId}
            type={InlineEditFieldTypes.Role}
            onChange={(_, value) => onChange(d.id, 'isAdmin', value)}
            onEdit={setInlineEditingId}
          />
        );
      },
    },
    {
      title: 'Active Account',
      dataIndex: '',
      key: 'isActivated',
      render: (d: IAccountDto) => {
        const fieldId = createIdForInlineEditField('isActivated', d.id);
        return (
          <InlineEditField
            id={fieldId}
            value={d.isActivated}
            label={d.isActivated ? 'YES' : 'NO'}
            isEditing={fieldId === inlineEditingId}
            onChange={(_, value) => onChange(d.id, 'isActivated', value)}
            onEdit={setInlineEditingId}
          />
        );
      },
    },
    {
      title: 'Accept Agreement',
      dataIndex: '',
      key: 'agreementAccepted',
      render: (d: IAccountDto) => {
        const fieldId = createIdForInlineEditField('agreementAccepted', d.id);
        return (
          <InlineEditField
            id={fieldId}
            value={d.agreementAccepted}
            label={d.agreementAccepted ? 'YES' : 'NO'}
            isEditing={fieldId === inlineEditingId}
            onChange={(_, value) => onChange(d.id, 'agreementAccepted', value)}
            onEdit={setInlineEditingId}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'delete',
      render: (d: IAccountDto) => (
        <div style={{ textAlign: 'center' }}>
          <Button
            shape="round"
            type="link"
            danger
            onClick={() =>
              deleteAccount(
                d,
                () => setAccounts(null),
                () => {
                  const token = getTokenFromString(document.cookie);
                  fetchAccountTableData(token, (data) => setAccounts(data));
                },
              )
            }
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const filteredAccoutns = filterAccountsForSearchQuery(query, accounts);

  return (
    <div className={styles.administration}>
      <div className={styles.wrapper}>
        <Card title="Accounts" bordered={true} style={{ borderRadius: '20px' }}>
          <>
            <Input.Search
              className={styles.input}
              placeholder="Search"
              enterButton
              onSearch={(value) => setSearchQuery(value)}
              onChange={(e) => {
                const value = (e.target.value || '').trim();
                if (value.length === 0) {
                  return setSearchQuery(value);
                }
              }}
            />
            {filteredAccoutns == null ? (
              <div style={{ textAlign: 'center' }}>
                <LoadingOutlined style={{ fontSize: 48 }} spin />
              </div>
            ) : (
              <AccountTable
                columnConfig={columnConfig}
                data={filteredAccoutns}
              />
            )}
          </>
        </Card>
      </div>
    </div>
  );
};

export default Administration;
