import React from 'react';

import Page404 from '@auth/login/site/pages/404';

const IndexPage = () => {
  return <Page404 />;
};

IndexPage.noLayout = true;

export default IndexPage;
