import * as React from 'react';

import styles from './Card.module.less';

interface IProps {
  style?: any;
  Icon: any;
  title: string;
  body: string;
  link: string;
}

export const Card = (props: IProps) => {
  const { Icon, link, style, title, body } = props;

  return (
    <div style={style} className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{body}</div>
      </div>
      <div>
        <a className={styles.button} href={link}>
          Go to documentation
        </a>
      </div>
    </div>
  );
};
