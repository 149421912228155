import React from 'react';

import CoverSVG from '../images/cover.svg';

import styles from './BannerSVG.module.less';

const BannerSVG = React.memo(() => {
  return (
    <section className={styles.wrapper}>
      <CoverSVG />
    </section>
  );
});

export default BannerSVG;
