import * as React from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';

import { LegalProvider } from 'bc-providers';
import { BackendModels } from 'bc-models';
import { getTokenFromString } from 'bc-cookies';

import Header from '../components/Header';
import { Footer } from '../components/Footer';
import { TermsAndConditionsContent } from '../components/TermsAndConditions';

import { Notification } from '../components/Notification';

import {
  isBrowser,
  serverSideRenderingStyles,
  isResponseError,
  handleError,
} from '../utils';

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  min-width: 600px;
  height: 100vh;
  min-height: 700px;
  background: linear-gradient(225deg, #ffffff, #f0f5ff);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .notification-dismiss {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ActionContentWrapper = styled.div`
  max-width: 60%;
  font-size: 30px;
  font-weight: 500;
  margin: auto;
  text-align: center;

  button {
    margin-top: 40px;
  }

  @media (max-width: 1150px) {
    max-width: 70%;
    font-size: 24px;
  }

  @media (max-width: 950px) {
    max-width: 70%;
    font-size: 18px;
  }
`;

const termsAndConditionModal = (
  onAccept: () => void,
  onDecline: () => void,
) => {
  Modal.destroyAll();
  Modal.confirm({
    title: 'Bluecode Developer Portal Agreement',
    content: <TermsAndConditionsContent />,
    width: '70%',
    okText: 'I do accept the Terms and Conditions',
    cancelText: 'Decline',
    onOk: onAccept,
    onCancel: onDecline,
  });
};

const AcceptContent = () => (
  <ActionContentWrapper>
    <div>
      You will automatically being forwarded to the Bluecode Developer Portal
      Welcome page
    </div>
  </ActionContentWrapper>
);

const DeclineContent = ({ onClick }: { onClick: () => void }) => (
  <ActionContentWrapper>
    <div>
      In order to access the Bluecode Developer Portal, you need to accept the
      Terms and Conditions first
    </div>
    <Button shape="round" onClick={onClick}>
      Open Terms and Conditions
    </Button>
  </ActionContentWrapper>
);

export interface ITermsAndConditionsPageProps {
  serviceApiUrl: string;
  rootDomain: string;
}

export const TermsAndConditions = (props: ITermsAndConditionsPageProps) => {
  const [hasAccepted, setHasAccepted] = React.useState<null | boolean>(null);

  const onDecline = () => setHasAccepted(false);
  const onAccept = async () => {
    if (hasAccepted === true) {
      return;
    }

    setHasAccepted(true);
    const token = getTokenFromString(document.cookie);
    const result = await LegalProvider.acceptOrDenyTermsAndConditions(
      true,
      token,
    );

    if (isResponseError(result)) {
      return handleError(result);
    }

    const data = result.data as BackendModels.ITermsAndConditionData;
    setTimeout(() => (window.location.href = data.redirect), 500);
  };
  const showModal = () => termsAndConditionModal(onAccept, onDecline);

  React.useEffect(() => {
    showModal();
    return () => Modal.destroyAll();
  }, []);

  if (isBrowser === false) {
    return <div style={serverSideRenderingStyles()} />;
  }

  (window as any).appBaseServiceUrl = props.serviceApiUrl;

  return (
    <Wrapper {...props}>
      <Header />
      <ContentWrapper>
        {hasAccepted == null ? null : hasAccepted ? (
          <AcceptContent />
        ) : (
          <DeclineContent onClick={showModal} />
        )}
      </ContentWrapper>
      <Footer />
      <Notification />
    </Wrapper>
  );
};

export default TermsAndConditions;
