import * as React from 'react';
import styled from 'styled-components';

import { Button, Descriptions } from 'antd';

import Header from '../components/Header';

import { Footer } from '../components/Footer';
import { LockerForm } from '../components/LockerForm';
import { LockerStates } from '../components/LockerComponent';
import { isBrowser } from 'bc-providers/dist/utils';
import { serverSideRenderingStyles } from '../utils';

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  min-width: 320px;
  height: 100vh;
  min-height: 600px;
  background: linear-gradient(225deg, #ffffff, #f0f5ff);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  button,
  a {
    padding-left: 0;
  }
`;

const FormWrapper = styled.div`
  max-width: 320px;
  min-height: 420px;
  background: white;
  padding: 24px;
  border: 1px solid #f0f0f0;
`;

export const Page404 = (props: any) => {
  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);
  const [lockerState, setLockerState] = React.useState(LockerStates.open);

  React.useEffect(() => {
    setLockerState(LockerStates.close);

    if (isBrowser === false) {
      return;
    }

    const resize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', resize, false);

    return () => window.removeEventListener('resize', resize, false);
  }, []);

  if (isBrowser === false) {
    return <div style={serverSideRenderingStyles()} />;
  }

  return (
    <Wrapper {...props}>
      <Header />
      <ContentWrapper>
        {(windowWidth || window.innerWidth) < 600 ? (
          <FormWrapper>
            <Descriptions title="404 - Not Found">
              <Descriptions.Item>
                Sorry, the page you visited does not exist.
              </Descriptions.Item>
            </Descriptions>
            <Button shape="round" type="link" htmlType="button" href="/">
              Back to login
            </Button>
          </FormWrapper>
        ) : (
          <LockerForm state={lockerState}>
            <Descriptions title="404 - Not Found">
              <Descriptions.Item>
                Sorry, the page you visited does not exist.
              </Descriptions.Item>
            </Descriptions>
            <Button type="link" htmlType="button" href="/">
              Back to login
            </Button>
          </LockerForm>
        )}
      </ContentWrapper>
      <Footer />
    </Wrapper>
  );
};

export default Page404;
